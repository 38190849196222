<template>
  <div>
    <div class="fullHeight is-flex is-flex-direction-column is-align-items-center is-justify-content-center" >
      <p class="title is-4 mb-4">Sign Up</p>
      <form @keyup.enter="signupRequest()">
        <b-field label="Your name" label-position="on-border">
          <b-input placeholder="First & last name" v-model="name" class="mb-2"></b-input>
        </b-field>
        <b-field label="Your work email" label-position="on-border">
          <b-input placeholder="Work Email" v-model="email" class="mb-2"></b-input>
        </b-field>
        <b-field label="A new password" label-position="on-border">
          <b-input placeholder="Password" v-model="password" type="password" class="mb-3" password-reveal></b-input>
        </b-field>
        <div class="mb-2">
          <b-switch v-model="isOwner" true-value="Yes, I am the shop owner" false-value="No, I am not the shop owner">
            {{ isOwner }}
          </b-switch>
        </div>
        
        <b-tooltip v-if="isOwner==='No, I am not the shop owner'" label="The shop owner should give this to you" >
          <b-field label="Shop ID" label-position="on-border">
            <b-input placeholder="Shop ID" v-model="shopID"></b-input>
          </b-field>
        </b-tooltip>
        

        <div class="is-flex is-justify-content-center mt-4">
          <b-button v-if="loadingLogin" rounded class="is-info is-light" loading>Sign up</b-button>
          <b-button v-else rounded class="is-info" @click="signupRequest()" type="submit">Sign up</b-button>
        </div>
      </form>
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mt-4">
        <p>Already have an account?
          <router-link to="login">Login in here.</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// @media screen and (min-width: 769px) {
//   input.input {
//     width: 30vw!important;
//   } 
// }
</style>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { db } from '@/main'

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loadingLogin: false,
      isOwner: "No, I am not the shop owner",
      shopID: "",
      name: "",
    };
  },
  methods: {
    signupRequest() {
      this.loadingLogin = true;
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
        .then((res) => {
          let isOwner, shopID;
          if (this.isOwner === 'No, I am not the shop owner') {
            isOwner = false;
          } else isOwner = true;
          if (isOwner) {
            shopID = res.user.uid
          } else shopID = this.shopID;
          db.collection('users').doc(res.user.uid).set({
            name: this.name,
            email: this.email,
            is_owner: isOwner,
            shop_id: shopID
          })
          this.loadingLogin = false;
          this.$router.replace("/dashboard");
        })
        .catch((error) => {
          console.error(error)
          this.loadingLogin = false;
        });
    }
  }
};
</script>